import React, {
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
    useImperativeHandle,
} from "react";
import Plot from "react-plotly.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import html2canvas from "html2canvas";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
} from "@material-ui/core";
import "./styles.css";
import logo from "./abm.png";
import { Box } from "@mui/material";
import { Drawer, Typography } from "@mui/material";
import { CloseOutlined, Tune } from "@mui/icons-material";
import { formatDate, getYearFromDate, formatDateTime } from '../../../utils'
import {
    Download,
    ZoomIn,
    Delete,
} from "@mui/icons-material";
const MultiSubplotsStacked = forwardRef(
    (
        { totalTherpies,
            setLoadingAutoPdf,
            setHidePreview,
            data,
            hidePreview,
            startDate,
            endDate,
            averageValues,
            serialNumber,
            handleGraphLoading,
        },
        ref
    ) => {
        const plotContainerRef = useRef(null);
        const [reportImages, setReportImages] = useState([]);
        const [summaries, setSummaries] = useState([]);
        const [updatingReport, setUpdatingReport] = useState(false);
        const [selectedImage, setSelectedImage] = useState(null);
        const [zoomLevel, setZoomLevel] = useState(1);
        const [isZoomed, setIsZoomed] = useState(false)
        let pdfName;
        const handleParentReport = () => {
            if (reportImages.length > 0) {
                setHidePreview(true);
                setLoadingAutoPdf(false);

            } else {
                addToReport();
                setLoadingAutoPdf(false);
            }
        };
        const addToReport = async () => {
            const captureType = sessionStorage.getItem('zoomStatus')
            setUpdatingReport(true);
            if (plotContainerRef.current) {
                await html2canvas(plotContainerRef.current, { scale: 2 })
                    .then((canvas) => {
                        const imgData = canvas.toDataURL("image/png");
                        setReportImages([...reportImages, imgData]);
                        setSummaries([
                            ...summaries,
                            captureType && captureType.trim() !== ''
                                ? `Settings vs Peak Pressure and Peak Flow (${captureType})`
                                : 'Settings vs Peak Pressure and Peak Flow'
                        ]);
                        setTimeout(() => {
                            setUpdatingReport(false);
                        }, 10);
                        if (reportImages.length > 0) setHidePreview(true);
                        setLoadingAutoPdf(false);
                    })
                    .catch((err) => {
                        console.error("Error capturing chart:", err);
                    });
            }
        };

        useImperativeHandle(ref, () => ({
            callChildFunction: downloadReport,
            addToReport,
            handleParentReport,
        }));

        const downloadReport = async () => {
            setLoadingAutoPdf(true);
            const pdf = new jsPDF("p", "pt", "a4");
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
            const margin = 60;
            const formattedStartDate = formatDate(startDate)
            const formattedEndDate = formatDate(endDate)
            function addHeaderWithLogosAndText(pdf) {
                const logoWidth = 600 * 0.16;
                const logoHeight = 426 * 0.16;
                pdf.setFontSize(12);
                const titleX = pageWidth / 2 - 40;
                const titleY = 50;
                pdf.text(`Therapy Compliance Report`, 21, titleY + 5, { align: "left" });
                pdf.setFontSize(9)
                pdf.text(`${formattedStartDate} to ${formattedEndDate}`, 21, titleY + 20, { align: "left" });
                pdf.addImage(logo, "PNG", pageWidth - margin - 44, 20, logoWidth, logoHeight);
                pdf.setFontSize(12);
            }
            function addFooter(pdf, pageNumber) {
                const options = { year: "numeric", month: "short", day: "numeric" };
                pdf.setFontSize(10);
                pdf.text(`Confidential Report Generated on: ${new Date().toLocaleDateString("en-US", options)}`,
                    margin,
                    pageHeight - 20
                );
                pdf.text(`Page: ${pageNumber}`, pageWidth - margin, pageHeight - 20, {
                    align: "right",
                });
            }
            function addTableAndSummary(pdf) {
                addHeaderWithLogosAndText(pdf);
                pdfName = `BiWaze®Cough_${serialNumber}_TherapyReport_${formattedStartDate}_${formattedEndDate}.pdf`;

                const keyStyle = {
                    fillColor: "#e8e8e8",
                    textColor: 0,
                    fontSize: 8,
                    fontStyle: "normal",
                    halign: "left",
                };

                const valueStyle = {
                    fillColor: "#fff",
                    textColor: 0,
                    fontSize: 8,
                    halign: "center",
                };
                const summaryDetails = [
                    [
                        { content: "System Name", styles: keyStyle },
                        {
                            content: "BiWaze  Cough",
                            styles: valueStyle
                        },
                        { content: "Serial Number", styles: keyStyle },
                        { content: serialNumber, styles: valueStyle },
                    ],
                    [
                        { content: `HMR (H:M:S) as of ${formatDate(endDate)}`, styles: keyStyle },
                        { content: averageValues["HMR"], styles: valueStyle },
                        { content: `Net Device Usage (H:M:S)`, styles: keyStyle },
                        { content: averageValues["usageHMR"], styles: valueStyle },
                    ],
                    [
                        { content: "Average Therapy Cycles/Session", styles: keyStyle },
                        { content: averageValues["average_NC"], styles: valueStyle },
                        { content: "Average Expiration Volume (mL)", styles: keyStyle },
                        {
                            content: averageValues["average_EV"]?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }), styles: valueStyle
                        },
                    ],
                    [
                        { content: "Average Therapy Sessions/Day", styles: keyStyle },
                        { content: averageValues["average_Therapy"], styles: valueStyle },
                        { content: "SD* of Expiratory Volume (mL)", styles: keyStyle },
                        {
                            content: averageValues["sdExpVol"].toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            }), styles: valueStyle
                        },
                    ],
                    [
                        { content: "Total Therapy Sessions", styles: keyStyle },
                        { content: totalTherpies, styles: valueStyle },
                        { content: "Average Peak Cough Flow (lpm)", styles: keyStyle },
                        { content: averageValues["average_PF"], styles: valueStyle },
                    ],
                    [
                        { content: "Number of Days With No Therapy", styles: keyStyle },
                        { content: averageValues["noDaysWithoutTherapy"], styles: valueStyle },
                        { content: "SD* of Peak Flow (lpm)", styles: keyStyle },
                        { content: averageValues["sdExpFlow"], styles: valueStyle },
                    ]
                ];

                pdf.setFontSize(8);
                pdf.autoTable({
                    body: summaryDetails,
                    startY: 83,
                    columnStyles: {
                        0: { minCellWidth: 80 },
                        1: { minCellWidth: 50 },
                        2: { minCellWidth: 80 },
                        3: { minCellWidth: 50 },
                    },
                    styles: {
                        fontSize: 8,
                        halign: "left",
                        cellPadding: 5,
                        lineWidth: 0.5,
                        lineColor: [200, 200, 200],
                    },
                    margin: { ...margin, left: 21, right: 22, top: 0 },
                });
                pdf.setFontSize(5);
                pdf.text("®", 261, 92)
                pdf.setFontSize(7);
                pdf.text("* SD stands for Standard Deviation, which represents the variation from the mean value.", 21, 209)
                const startYear = getYearFromDate(startDate);
                const endYear = getYearFromDate(endDate);
                if (
                    isNaN(startYear) ||
                    isNaN(endYear) ||
                    startYear < 2020 ||
                    endYear > new Date().getFullYear() + 1
                ) {
                    const warnings = "Note: We found dates on your BiWaze  Cough system that may be incorrect. Please verify and update the system date to ensure accurate therapy dates moving forward.";
                    pdf.setFontSize(7);
                    pdf.text(warnings, 21, 218);
                    pdf.setFontSize(4);
                    pdf.text("®", 140, 216)
                }


            }
            function addFirstImage(pdf) {
                reportImages.slice(0, 1).forEach((imgData, index) => {
                    pdf.setFontSize(10);
                    const summaryText = summaries[index];
                    const imageY = 230;
                    const imageWidth = 560;
                    const imageHeight = 500;
                    pdf.addImage(imgData, "PNG", 17, imageY, imageWidth, imageHeight, undefined, 'FAST');
                    const imageCenterY = imageY + 30 + imageHeight / 2;
                    pdf.setFontSize(10);
                    pdf.text(summaryText, 21, imageCenterY - 275);
                });

            }
            addTableAndSummary(pdf);
            addFirstImage(pdf)
            addFooter(pdf, 1);
            if (reportImages.length === 0) {
                if (plotContainerRef.current) {
                    try {
                        plotRefs.current.forEach(async (plot, index) => {
                            const canvas = await html2canvas(plot.current, { scale: 2 });
                            const imgData = canvas.toDataURL("image/png");
                            setReportImages([...reportImages, imgData]);
                            pdf.addPage();
                            addHeaderWithLogosAndText(pdf);

                            pdf.setFontSize(14);
                            pdf.addImage(imgData, "PNG", 0, 120, 500, 500, undefined, 'FAST');
                            pdf.setFontSize(9);
                            addFooter(pdf, 0 + 2);
                        });
                        setUpdatingReport(false);
                        pdf.save(pdfName);
                        setLoadingAutoPdf(false);
                    } catch (err) {
                        console.error("Error capturing chart:", err);
                    }
                }
            } else {
                reportImages.slice(1).forEach((imgData, index) => {
                    pdf.addPage();
                    addHeaderWithLogosAndText(pdf);
                    pdf.setFontSize(10);
                    const imageY = 130;
                    const imageWidth = 500;
                    const imageHeight = 500;
                    pdf.addImage(imgData, "PNG", 18, imageY, imageWidth + 58, imageHeight, undefined, 'FAST');
                    const imageCenterY = imageY + 30 + imageHeight / 2;
                    pdf.setFontSize(10);
                    pdf.text(summaries[index + 1], 21, imageCenterY - 275);
                    pdf.setFontSize(9);
                    addFooter(pdf, index + 2);
                });
                pdf.save(pdfName);
                setLoadingAutoPdf(false);
            }
        };

        const handleImageClick = (img) => {
            setSelectedImage(img);
            setZoomLevel(1);
        };

        const handleDeleteImage = (indexToDelete) => {
            setReportImages(
                reportImages.filter((_, index) => index !== indexToDelete)
            );
            setSummaries(summaries.filter((_, index) => index !== indexToDelete));
        };

        const handleZoomIn = () => {
            setZoomLevel((prev) => prev + 0.2);
        };

        const handleZoomOut = () => {
            setZoomLevel((prev) => Math.max(prev - 0.2, 1));
        };

        const handleCloseDialog = () => {
            setSelectedImage(null);
        };
        const mergeIndividualTherapyValues = (data, axis, start, end) => {
            return data
                ?.slice(0, data?.P_IP?.length)
                ?.map((entry) => entry[axis])
                ?.flat()
                ?.slice(start, end);
        };
        const generatePlotData = (data, start, end) => {
            const plotData = [
                {
                    x: data.Cycle_P_P[0]?.x?.slice(start, end),
                    y: data.Cycle_P_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Pause Pressure",
                    marker: {
                        color: "rgba(68, 187, 68, 0.8)",
                        line: {
                            color: "rgba(68, 187, 68, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "")
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: (data.Cycle_P_P[0]?.o?.slice(start, end) || []).map(
                                (oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(0, 128, 0, 0.7)"
                                        : "rgba(68, 187, 68, 0.8)"
                            ),
                            bgcolor: "rgba(68, 187, 68, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cm H₂O ",

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_I_P[0]?.x?.slice(start, end),
                    y: data.Cycle_I_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Insp Pressure",
                    marker: {
                        color: "rgb(20, 97, 211, 0.8)",
                        line: {
                            color: "rgb(20, 97, 211, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_I_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 2 ? "/" : "")
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_I_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 2
                                        ? "rgba(20, 128, 211, 0.7)"
                                        : "rgba(20, 97, 211, 0.8)"
                                ),
                            bgcolor: "rgba(20, 97, 211, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cm H₂O ",

                    yaxis: "y1",
                },
                {
                    x: data.Cycle_E_P[0]?.x?.slice(start, end),
                    y: data.Cycle_E_P[0]?.y?.slice(start, end),
                    type: "bar",
                    name: "Set Exp Pressure",
                    marker: {
                        color: "rgb(255, 174, 66,0.8)",
                        line: {
                            color: "rgb(255, 174, 66, 1)",
                            width: 1,
                        },
                        pattern: {
                            shape:
                                data.Cycle_E_P[0]?.o?.slice(start, end).map(
                                    (oValue) => (oValue === 1 || oValue === 3 ? "/" : "")
                                ) || "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: data.Cycle_E_P[0]?.o
                                ?.slice(start, end)
                                .map((oValue) =>
                                    oValue === 1 || oValue === 3
                                        ? "rgba(255, 128, 66, 0.7)"
                                        : "rgba(255, 174, 66, 0.8)"
                                ),
                            bgcolor: "rgba(255, 174, 66, 0.5)",
                        },
                    },
                    hovertemplate: "%{y} cm H₂O ",

                    yaxis: "y1",
                },
                {
                    x: [0],
                    y: [0],
                    type: "bar",
                    name: "Oscillations Enabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#1461d3",
                            width: 1,
                        },
                        pattern: {
                            shape: "/",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#1461d3",
                            bgcolor: "r#1461d3",
                        },
                    },
                    yaxis: "y2",
                },
                {
                    x: mergeIndividualTherapyValues(data.P_F, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_F, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Peak Expiratory Flow (lpm)",
                    line: { color: "grey", dash: "dot" },
                    yaxis: "y2",
                    hovertemplate: "%{y} lpm ",
                },
                {
                    x: mergeIndividualTherapyValues(data.P_IP, "x", start, end),
                    y: mergeIndividualTherapyValues(data.P_IP, "y", start, end),
                    type: "box",
                    mode: "lines",
                    name: "Peak Insp Pressure",
                    line: { color: "red", dash: "dot" },
                    yaxis: "y1",
                    // marker: {
                    //     color: 'rgba(255, 0, 0, 0.8)', // Marker color
                    // },
                    hovertemplate: "%{y} cm H₂O",
                    boxpoints: "all",
                    pointpos: 0.1,
                },
                {
                    x: mergeIndividualTherapyValues(data.E_V, "x", start, end),
                    y: mergeIndividualTherapyValues(data.E_V, "y", start, end),
                    type: "scatter",
                    mode: "lines+markers",
                    name: "Expiratory Volume (ml)",
                    line: { color: "blue", dash: "dash" },
                    yaxis: "y3",
                    hovertemplate: "%{y} ml ",
                },
                {
                    x: [0],
                    y: [0],
                    type: "box",
                    name: "Oscillations Disabled",
                    marker: {
                        color: "#1461d3",
                        line: {
                            color: "#fff",
                            width: 1,
                        },
                        pattern: {
                            shape: "",
                            size: 10,
                            solidity: 0.3,
                            fgcolor: "#fff",
                            bgcolor: "#fff",
                        },
                    },
                    yaxis: "y2",
                },
            ];
            return plotData
        };
        let rangeCount = 0;
        const generateRanges = (data, max = 5000) => {
            rangeCount = Math.ceil(data.Cycle_P_P[0]?.x?.length / max);
            let chunks = [];
            for (let i = 0; i < rangeCount; i++) {
                const start = i * 300;
                const end = Math.max((i + 1) * 300, data.Cycle_P_P[0]?.x?.length);
                chunks.push(generatePlotData(data, start, end));
            }
            return chunks;
        };
        function calculateCategoryTickvals(categories, maxTicks) {
            const step = Math.ceil(categories.length / maxTicks);
            return categories.filter((_, index) => index % step === 0);
        }

        const xValues = data?.P_F?.flatMap((item) => item.x);
        const maxTicks = 10;
        const tickvals = calculateCategoryTickvals(xValues, maxTicks);

        function calculateDynamicDtick(minY, maxY, desiredTicks = 100) {
            const range = maxY - minY;
            if (range <= 0) return 1;
            const rawInterval = range / desiredTicks;
            const magnitude = Math.pow(10, Math.floor(Math.log10(rawInterval)));
            const niceInterval = Math.ceil(rawInterval / magnitude) * magnitude;
            return niceInterval;
        }

        const yValues = data.E_V.flatMap((item) => item.y) ?? [];
        const minY = Math.min(...yValues);
        const maxY = Math.max(...yValues);
        const dynamicDtick = calculateDynamicDtick(minY, maxY);
        const layout = {
            width: 1300,
            xaxis: {
                showgrid: true,
                tickvals: tickvals,
                type: "category",
                ticktext: tickvals < 15 ? tickvals : tickvals.map(dateTime => {
                    const date = new Date(dateTime);
                    const options = { year: 'numeric', month: 'short', day: 'numeric' };
                    return formatDateTime(dateTime);
                })
            },
            yaxis: {
                title: "Pressure (cm H₂O)",
                side: "left",
                position: 0,
                autorange: false,
                range: [-90, 90],
                fixedrange: true,

            },
            yaxis2: {
                title: "Peak Expiratory Flow (lpm)",
                overlaying: "y",
                side: "right",
                position: 1,
                autorange: false,
                range: [90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) + 100, -90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) - 100],
                showgrid: false,
                fixedrange: true,
            },
            yaxis3: {
                title: "Expiratory volume (ml)",
                side: "left",
                anchor: "free",
                domain: [0, 0.45],
                position: 0,
                tickformat: ".f",
                range: [minY, maxY + 5000],
                fixedrange: true,
            },
            grid: {
                rows: 2,
                columns: 1,
                pattern: "independent",
                roworder: "top to bottom",
                rowgap: 0.1,
            },
            height: 1000,
            showlegend: true,
            legend: {
                orientation: "h",
                x: 1,
                y: 1,
                xanchor: "right",
                yanchor: "bottom",
                traceorder: "normal",
            },

            dragmode: "zoom",
            selectionmode: "rectangle",
            selectdirection: "h"
        };

        const plotRefs = useRef([]);
        const getLayout = (chunk, index) => {
            const tickvals = chunk[0].x.map(t => new Date(t.replace(" ", "T")).getTime());

            const containerWidth = window.innerWidth;
            const containerHeight = window.innerHeight;
            return {
                width: containerWidth * 0.80,
                // height: containerHeight * 1.3,
                // width: containerWidth - 100,
                height: containerHeight * 1.4,
                grid: {
                    rows: 2,
                    columns: 1,
                    pattern: "coupled",
                    roworder: "top to bottom",
                    ygap: 0.3
                },
                xaxis: {
                    showgrid: true,
                    type: "category",
                    tickmode: "auto",
                    tickfont: {
                        size: 12,
                    },
                    nticks: 30
                },
                yaxis: {
                    title: {
                        text: 'Pressure (cm H₂O)',
                        font: {
                            size: 18
                        }
                    },
                    side: "left",
                    position: 0,
                    autorange: false,
                    range: [-90, 90],
                    fixedrange: true,
                    showgrid: false,

                },
                yaxis2: {
                    title: {
                        text: 'Peak Expiratory Flow (lpm)',
                        font: {
                            size: 18
                        }
                    },
                    overlaying: "y",
                    side: "right",
                    position: 1,
                    autorange: false,
                    range: [90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) + 100, -90 * (Math.max(...(data.P_F.flatMap((item) => item.y))) / 90) - 100],
                    showgrid: false,
                    fixedrange: true,
                },
                yaxis3: {
                    title: {
                        text: 'Expiratory Volume (ml)',
                        font: {
                            size: 18
                        }
                    },
                    side: "left",
                    showgrid: false,
                    zeroline: false,
                    showticklabels: true,
                    domain: [0, 0.45],
                    tickformat: ".f",
                    range: [
                        Math.min(...chunk.map((item) => item.y)) - 5000,
                        Math.max(...chunk.map((item) => item.y)) + 5000,
                    ],
                    // fixedrange: true,
                },
                showlegend: true,
                legend: {
                    orientation: "h",
                    x: 1,
                    y: 1,
                    xanchor: "right",
                    yanchor: "bottom",
                    traceorder: "normal",
                    font: {
                        size: 14
                    },
                    groupclick: "togglegroup"
                },
                // shapes: [
                //     // Horizontal line for max value
                //     {
                //         type: "line",
                //         xref: "paper",
                //         x0: 0,
                //         x1: 1,
                //         yref: "y3",
                //         y0: maxY,
                //         y1: maxY,
                //         line: {
                //             color: "grey",
                //             width: 2,
                //             dash: "line", // Dashed line
                //         },
                //     },
                //     // Horizontal line for min value
                //     {
                //         type: "line",
                //         xref: "paper",
                //         x0: 0,
                //         x1: 1,
                //         yref: "y3",
                //         y0: minY,
                //         y1: minY,
                //         line: {
                //             color: "grey",
                //             width: 2,
                //             dash: "line", // Dashed line
                //         },
                //     },
                // ],
                // annotations: [
                //     {
                //         x: 1, // Position at the end of the line
                //         y: maxY, // y position of the max line
                //         xref: "paper",
                //         yref: "y3",
                //         text: `Max Value: ${maxY}`, // Hover text
                //         showarrow: false,
                //         font: {
                //             size: 12,
                //             color: "grey",
                //         },
                //         align: "right",
                //     },
                //     {
                //         x: 1, // Position at the end of the line
                //         y: minY, // y position of the min line
                //         xref: "paper",
                //         yref: "y3",
                //         text: `Min Value: ${minY}`, // Hover text
                //         showarrow: false,
                //         font: {
                //             size: 12,
                //             color: "grey",
                //         },
                //         align: "right",
                //     },
                // ],
                dragmode: "zoom",
                selectionmode: "rectangle",
                selectdirection: "h",
            }
        }
        const handleEvent = (eventName) => (event) => sessionStorage.setItem('zoomStatus', 'Zoomed');

        return (
            <div>
                <div className="">
                    <div className=" overflow-y-auto " ref={plotContainerRef}>
                        {data &&
                            data.P_F &&
                            data.P_F[0] &&
                            generateRanges(data).map((chunk, index) => {
                                if (!plotRefs.current[index]) {
                                    plotRefs.current[index] = React.createRef();
                                }
                                return (
                                    <div
                                        className="overflow-y-auto plot-item  h-96 p-2 shadow-lg bg-gray-100 rounded-md"
                                        key={index}
                                        ref={plotRefs.current[index]}
                                    >
                                        <div className="border-2 border-black mt-6  inline-block">
                                            <Plot
                                                data={chunk}
                                                layout={getLayout(chunk, index)}
                                                config={{ responsive: true }}
                                                onRelayout={handleEvent("onRelayout")}
                                            /></div>


                                    </div>
                                );
                            })}
                    </div>
                </div>

                <Drawer
                    anchor="right"
                    open={hidePreview}
                    onClose={() => setHidePreview(false)}
                >
                    <Box sx={{ width: "400px", padding: "20px" }}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "20px",
                            }}
                        >
                            <Typography variant="h6" style={{ alignItems: "center" }}>Generate Report</Typography>
                            <CloseOutlined
                                onClick={() => setHidePreview(false)}
                                sx={{ cursor: "pointer", color: "#333" }}
                            />
                        </div>
                        <div
                            style={{
                                backgroundColor: "#f9f9f9",
                                padding: "16px",
                                borderRadius: "12px",
                                boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                                overflowY: "auto",
                                transition: "all 0.3s ease",
                            }}
                        >
                            {/* Button Group */}
                            <div
                                className="button-group"
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    gap: 10
                                }}
                            >
                                <div className="graph-instructions">
                                    <h3>Add Graph to Your Report</h3>
                                    <p>
                                        You can customize and add the current graph to your report:
                                    </p>
                                    <ul>
                                        <li>Hover over the graph to resize, zoom, or crop.</li>
                                        <li>
                                            Use filters like <strong>Date Range</strong> or{" "}
                                            <strong>Therapy</strong> to refine the view.
                                        </li>
                                        <li>
                                            Once ready, click <strong>Add Graph to Report</strong> to
                                            include it in your report.
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    className="custom-button"
                                    variant="contained"
                                    onClick={downloadReport}
                                    disabled={!reportImages.length > 0}
                                    startIcon={
                                        <Download
                                            sx={{
                                                fontSize: "20px",
                                                transition: "transform 0.3s ease",
                                            }}
                                        />
                                    }
                                    sx={{
                                        backgroundColor: "#2196F3",
                                        color: "#fff",
                                        fontWeight: "bold",
                                        height: "50px",
                                        borderRadius: "10px",
                                        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                                        cursor: !reportImages.length > 0 ? "none" : "pointer",
                                        transition:
                                            "transform 0.2s ease, background-color 0.3s ease",
                                        "&:hover": {
                                            backgroundColor: "#1E88E5",
                                            transform: "scale(1.03)",
                                        },
                                    }}
                                >
                                    Download
                                </Button>
                            </div>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    padding: "8px",
                                    backgroundColor: "#f9f9f9",
                                    borderRadius: "8px",
                                    margin: "10px",
                                }}
                            >
                                <Typography
                                    variant="h6"
                                    component="div"
                                    style={{ color: "#333", fontWeight: "bold", flexGrow: 1 }}
                                >
                                    Selected Graphs: {reportImages.length}
                                </Typography>
                            </div>
                            <div className="report-list">
                                <div
                                    style={{
                                        maxHeight: "400px",
                                        overflowY: "auto",
                                        overflowX: "hidden",
                                        padding: "10px",
                                    }}
                                >
                                    {reportImages.map((img, index) => (
                                        <div className="report-item" key={index} style={{ marginBottom: "10px" }}>
                                            <img src={img} alt={`Report ${index}`} />
                                            <div className="image-overlay">
                                                {summaries[index]?.includes("(") && (
                                                    <span>
                                                        {`(${summaries[index]?.split("(")[1]}`}
                                                    </span>
                                                )}
                                                <IconButton
                                                    onClick={() => handleImageClick(img)}
                                                    sx={{
                                                        color: "#ffffff",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <ZoomIn sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => handleDeleteImage(index)}
                                                    sx={{
                                                        color: "#FF5252",
                                                        transition: "transform 0.3s ease",
                                                        "&:hover": { transform: "scale(1.2)" },
                                                    }}
                                                >
                                                    <Delete sx={{ fontSize: "20px" }} />
                                                </IconButton>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                {reportImages.length < 1 && (
                                    <p
                                        style={{
                                            fontSize: "14px",
                                            color: "#666666",
                                            textAlign: "center",
                                        }}
                                    >
                                        Click on 'Add Graph to Report' to copy the graphs and add
                                        them to the report for download.
                                    </p>
                                )}
                            </div>
                        </div>
                    </Box>
                </Drawer>

                {/* Dialog for image viewing */}
                <Dialog
                    open={!!selectedImage}
                    onClose={handleCloseDialog}
                    maxWidth="md"
                >
                    <DialogTitle>Image Viewer</DialogTitle>
                    <DialogContent>
                        <div className="image-viewer">
                            <img
                                src={selectedImage}
                                alt="Selected report"
                                style={{ transform: `scale(${zoomLevel})` }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleZoomIn} variant="contained">
                            Zoom In
                        </Button>
                        <Button onClick={handleZoomOut} variant="contained">
                            Zoom Out
                        </Button>
                        <Button
                            onClick={handleCloseDialog}
                            variant="contained"
                            color="secondary"
                        >
                            Close
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
);

export default MultiSubplotsStacked;
